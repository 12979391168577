import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import newsStyles from "./news.module.scss"
import Layout from "../components/layout"
import Head from "../components/head"
import { BLOCKS } from "@contentful/rich-text-types"

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "news" }) {
      title
      date
      body {
        json
      }
    }
    allSitePage(filter: { context: { tag: { eq: "news" } } }) {
      edges {
        node {
          id
          path
          context {
            title
            description
            slug
            date
            cover {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const alt = node.data.target.fields.title["en-US"]
      const url = node.data.target.fields.file["en-US"].url
      return <img alt={alt} src={url} />
    },
  },
}

const List = props => {
  return (
    <Layout>
      <Head title={props.data.contentfulPage.title} />
      <section className="hero mb-0 pb-0 is-small is-bold content">
        <div className="container">
          <div className="hero-body">
            <div className="container has-text-centered">
              {props.data.allSitePage.edges.length > 0
                ? documentToReactComponents(
                    props.data.contentfulPage.body.json,
                    options
                  )
                : "Няма нищо тук"}
            </div>
          </div>
        </div>
      </section>
      {props.data.allSitePage.edges.map(edge => {
        return (
          <section key={edge.node.id} className="hero ">
            <div className="hero-body mt-0 pt-0">
              <div className="container">
                <div className="columns">
                  <div className="column is-8 is-offset-2">
                    <figure className="image ">
                      <Link to={edge.node.path}>
                        {edge.node.context.cover.src ? (
                          <Img
                            style={{ height: "400px" }}
                            fluid={edge.node.context.cover}
                          />
                        ) : (
                          <img
                            style={{ height: "400px", objectFit: "cover" }}
                            src="https://source.unsplash.com/collection/1922729/800x400"
                            alt=""
                          />
                        )}
                      </Link>
                    </figure>
                  </div>
                </div>

                <section className="section">
                  <div className="columns">
                    <div className="column is-8 is-offset-2">
                      <div className=" is-medium">
                        <h2 className="subtitle is-4">
                          {edge.node.context.date}
                        </h2>
                        <h1 className="title">
                          <Link
                            key={edge.node.context.id}
                            className="is-uppercase"
                            to={edge.node.path}
                          >
                            {edge.node.context.title}
                          </Link>
                        </h1>
                        <p>{edge.node.context.description}</p>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="is-divider" />
              </div>
            </div>
          </section>
        )
      })}
    </Layout>
  )
}

export default List
